import React from "react";
import Page from "../../components/Page";
import { stoicismQoutes } from "../../data/qoutes";
import image from "../../images/stoicism.jpg"
import QoutesPage from "../../components/Citat/QoutesPage";
import Qoute from "../../components/Citat/Qoute";

export default function Citat() {
  const title = "De bästa citaten från Stoikerna"

  return (
    <>
      <Page
        title={title}
        description="Ett urval av de bästa citaten från flera av de största stoikerna inom filosofin stoicismen. Blandade citat från Marcus Aurelius, Seneca och Epictetus."
      >
        <QoutesPage title={title}>
          <img src={image} className="qouteImage" alt="citat stoicismen" />
          <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8 mt-6">{title}</h1>
          <p>Nedan hittar du ett urval av våra favoritcitat från flera av de största stoikerna. Blandade citat från Marcus Aurelius, Seneca och Epictetus.</p>
          <div className="qoutesList">
            {stoicismQoutes.map((q, index) => {
              return <Qoute key={index} text={q.qoute} author={q.author} number={index + 1} />
            })}
          </div>
        </QoutesPage>
      </Page>
    </>
  );
}
